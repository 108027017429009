/* You can add global styles to this file, and also import other style files */

@import './bootstrap.min.css';
@import './assets/theme/theme-val.scss';
:root {
  --a-color: #555;
  --a-bg-color: #e7e7e7;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}
.ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected, .ck.ck-editor__editable.ck-blurred .ck-widget.ck-widget_selected:hover {
  outline-color: #ffffff00 !important;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.content-row {
  padding: 16px 0 48px 0;
}

.router-link-active {
  color: var(--a-color);
  background-color: var(--a-bg-color);
}

.cursor-pointer {
  cursor: pointer;
}

.category {
  font-size: x-large;
  color: $category-color;
  margin: 32px 0 48px 0;
}
.sub-category {
  cursor: pointer;
  color: $light-content-color;
  margin: 0 16px 32px 16px;
}
.sub-title-label {
  color: $light-content-color;
}

.elm-title, .elm-title-ly2 {
  font-size: large;
  color: $title-color;
  margin-top: 16px;
}
.elm-title-ly2 {
  margin-top: 0;
}
.elm-subtitle {
  color: $light-content-color;
  margin: 4px 0 16px 0;
}
.elm-content, .elm-content-ly2, .elm-content-rc {
  color: $light-content-color;
  
  // truncate, 2 lines
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.elm-content-ly2 {
  -webkit-line-clamp: 3;
}
.elm-content-rc {
  -webkit-line-clamp: 5;
}

.box {
  position: relative;
  width: 100%;// desired width
  &:before {
    content: "";
    display: block;
    padding-bottom: 66%; 
  }
} 
.box-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
}
