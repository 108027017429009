$header-color: white;
$header-color-tran: #ffffff94;
$header-background: #1b4a83;
$header-background-tran: #1b4a83be;

$primary-color: #1f73c7;
$secondary-color: #99CCFF;
$warn-color: #FF0033;
$slide-toggle-checked: #6699FFAA;
$slide-toggle-warn-checked: #FFCC33AA;

$primary-bg: white;
$secondary-bg: #f1f1f1;
$category-color: #233877;
$title-color: #000;
$light-content-color: #999;
$gray-content-color: #666;
$main-color: #333;
